import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { RepeatExpectation, PeriodicExpectationDTO } from '../models';
import { Expectation, ExpectationDTO } from '../models/expectation';

// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};
@Injectable({
  providedIn: 'root',
})
export class ExpectationsService {
  private readonly apiUrl: string = environment.apiUrl;

  private readonly expectationListBySpendingPlanSubject = new BehaviorSubject<Array<Expectation>>([]);
  readonly expectationListBySpendingPlan$ = this.expectationListBySpendingPlanSubject.asObservable();

  get expectationListBySpendingPlan(): Array<Expectation> {
    return this.expectationListBySpendingPlanSubject.getValue();
  }

  getExpectationByCategory(categoryId: string): Expectation {
    return this.expectationListBySpendingPlan.find((expectation: Expectation) => expectation.categoryId === categoryId);
  }

  setExpectationListBySpendingPlan(expectationList: Array<Expectation>): void {
    this.expectationListBySpendingPlanSubject.next(expectationList);
  }

  constructor(private http: HttpClient) {}

  async saveExpectation(expectation: Expectation, spendingPlanId: string): Promise<Expectation> {
    if (expectation.id) {
      return this.editExpectation(expectation, spendingPlanId);
    }
    return this.createExpectation(expectation, spendingPlanId);
  }

  async editExpectation(editExpectation: Expectation, spendingPlanId: string): Promise<Expectation> {
    let expectationFetched: Expectation = null;
    if (environment.useMocks) {
      // TODO
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/${spendingPlanId}/expectations/${editExpectation.id}`;
      const {
        spendingPlan: {
          expectations: [expectation],
        },
      } = await this.http.put<{ spendingPlan: { expectations: ExpectationDTO[] } }>(url, editExpectation).toPromise();
      expectationFetched = new Expectation().deserialize(expectation);
    }

    return expectationFetched;
  }

  async createExpectation(exp: Expectation, spendingPlanId: string): Promise<Expectation> {
    let expectationFetched: Expectation = null;
    const newExpectation = new Expectation(exp);
    newExpectation.periodMonth = newExpectation.periodMonth ? newExpectation.periodMonth - 1 : -1;
    if (environment.useMocks) {
      // TODO
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/${spendingPlanId}/expectations/`;
      const {
        spendingPlan: {
          expectations: [expectation],
        },
      } = await this.http.post<{ spendingPlan: { expectations: ExpectationDTO[] } }>(url, newExpectation).toPromise();
      expectationFetched = new Expectation().deserialize(expectation);
    }

    return expectationFetched;
  }

  async getExpectationsSpendingPlan(spendingPlanId: string, year: number, month: number): Promise<Array<Expectation>> {
    let expectationFetched: Array<Expectation> = [];
    if (environment.useMocks) {
      // spendingPlanFetched = new SpendingPlan().deserialize(SPENDINGPLANSLIST[1]);
    } else {
      const monthActual = month ? month - 1 : -1;
      const url = `${this.apiUrl}/client/spendingPlans/${spendingPlanId}/expectations?y=${year}&m=${monthActual}`;
      const { spendingPlan } = await this.http
        .get<{ spendingPlan: { expectations: Array<ExpectationDTO> } }>(url)
        .toPromise();
      expectationFetched = new Expectation().deserializeArray(spendingPlan.expectations);
    }
    this.setExpectationListBySpendingPlan(expectationFetched);

    return expectationFetched;
  }

  async deleteExpectationsOnSpendingPlan(expectations: Array<Expectation>, spendingPlanId: string): Promise<void> {
    await Promise.all(
      expectations.map(async (expectation: Expectation) => {
        await this.deleteExpectation(expectation.id, spendingPlanId);
      }),
    );
  }

  async deleteExpectationsIdOnSpendingPlan(expectationsIds: Array<string>, spendingPlanId: string): Promise<void> {
    await Promise.all(
      expectationsIds.map(async (id: string) => {
        await this.deleteExpectation(id, spendingPlanId);
      }),
    );
  }

  async deleteExpectation(expectationId: string, spendingPlanId: string): Promise<void> {
    if (environment.useMocks) {
      // eslint-disable-next-line no-console
      console.log(':::Deleted Expectation:::');
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/${spendingPlanId}/expectations/${expectationId}`;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
      const response = await this.http.delete(url).toPromise();
    }
  }

  async addExpectationsOnSpendingPlan(
    expectations: Array<number>,
    categoryId: string,
    spendingPlanId: string,
    year: number,
  ): Promise<void> {
    await Promise.all(
      Object.keys(expectations).map(async (key: string) => {
        const expectation = new Expectation();
        expectation.amount = expectations[key];
        expectation.adjustment = 0;
        expectation.categoryId = categoryId;
        expectation.periodMonth = parseInt(key, 10);
        expectation.periodYear = year;
        if (!expectation.amount) {
          await this.createExpectation(expectation, spendingPlanId); // TODO save => expectation.id
        }
      }),
    );
  }

  async getExpectationsByCategoryIdAndYear(
    spendingPlanId: string,
    categoryId: string,
    year: number,
  ): Promise<Array<Expectation>> {
    let expectationFetched: Array<Expectation> = [];
    if (environment.useMocks) {
      // spendingPlanFetched = new SpendingPlan().deserialize(SPENDINGPLANSLIST[1]);
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/${spendingPlanId}/expectations/byCategory/${categoryId}?y=${year}`;
      const { expectations } = await this.http.get<{ expectations: Array<ExpectationDTO> }>(url).toPromise();
      expectationFetched = new Expectation().deserializeArray(expectations);
    }

    return expectationFetched;
  }

  async getPeriodicExpectationsSpendingPlan(spendingPlanId: string, year: number): Promise<RepeatExpectation[]> {
    let expectationFetched: RepeatExpectation[] = [];
    if (environment.useMocks) {
      // spendingPlanFetched = new SpendingPlan().deserialize(SPENDINGPLANSLIST[1]);
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/${spendingPlanId}/scheduledExp?y=${year}`;
      const { scheduledExpectations } = await this.http
        .get<{ scheduledExpectations: Array<PeriodicExpectationDTO> }>(url)
        .toPromise();

      expectationFetched = new RepeatExpectation().deserializeArray(scheduledExpectations);
    }

    return expectationFetched;
  }

  async getFixedExpectationsSpendingPlan(spendingPlanId: string, year: number): Promise<RepeatExpectation[]> {
    const expectationFetched: RepeatExpectation[] = [];

    if (environment.useMocks) {
      // spendingPlanFetched = new SpendingPlan().deserialize(SPENDINGPLANSLIST[1]);
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/${spendingPlanId}/expectations/fixed?y=${year}`;
      const { data } = await this.http.get<{ data: { [key: string]: ExpectationDTO[] } }>(url).toPromise();

      Object.entries(data).forEach(([key, values]) => {
        expectationFetched.push(
          new RepeatExpectation().deserialize({
            id: values.map(({ id }) => id).join(','),
            categoryId: key,
            year,
          }),
        );
      });
    }

    return expectationFetched;
  }

  async getFixedExpectations(spendingPlanId: string, year: number): Promise<Map<string, Expectation[]>> {
    const expectationFetched: Map<string, Expectation[]> = new Map<string, Expectation[]>();
    if (environment.useMocks) {
      // useMocks
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/${spendingPlanId}/expectations/fixed?y=${year}`;
      const { data } = await this.http.get<{ data: { [key: string]: ExpectationDTO[] } }>(url).toPromise();
      Object.keys(data).forEach((key: string) =>
        expectationFetched.set(key, new Expectation().deserializeArray(data[key])),
      );
    }

    return expectationFetched;
  }

  async addPeriodicExpectations(
    spendingPlanId: string,
    newPeriodicExpectations: RepeatExpectation[],
    year: number,
  ): Promise<void> {
    await Promise.all(
      newPeriodicExpectations.map(async (periodicExpectation: RepeatExpectation) => {
        await this.addPeriodicExpectation(spendingPlanId, periodicExpectation, year);
      }),
    );
  }

  async updateExpectationsFixedAndPeriodic(
    spendingPlanId: string,
    repeatExpectation: RepeatExpectation,
    year: number,
  ): Promise<Expectation[]> {
    if (!repeatExpectation.id && repeatExpectation.isPeriodicChecked) {
      const newPeriodicExpectation = new RepeatExpectation(repeatExpectation);
      newPeriodicExpectation.year = year;
      return this.addPeriodicExpectation(spendingPlanId, newPeriodicExpectation, year);
    }
    return this.saveExpectationsOfPeriodicExpectation(spendingPlanId, repeatExpectation, year);
  }

  async addPeriodicExpectation(
    spendingPlanId: string,
    newPeriodicExpectation: RepeatExpectation,
    year: number,
  ): Promise<Expectation[]> {
    let expectationsSaved = [];

    if (environment.useMocks) {
      // spendingPlanFetched = new SpendingPlan().deserialize(SPENDINGPLANSLIST[1]);
    } else {
      expectationsSaved = await this.saveExpectationsOfPeriodicExpectation(
        spendingPlanId,
        newPeriodicExpectation,
        year,
      );
      const newScheduledExpectations: PeriodicExpectationDTO = {
        categoryId: newPeriodicExpectation.subCategory.id,
        year,
      };

      const url = `${this.apiUrl}/client/spendingPlans/${spendingPlanId}/scheduledExp`;
      const { scheduledExpectation } = await this.http
        .post<{ scheduledExpectation: PeriodicExpectationDTO }>(url, newScheduledExpectations)
        .toPromise();
      new RepeatExpectation().deserialize(scheduledExpectation);
    }

    return expectationsSaved;
  }

  async saveExpectationsOfPeriodicExpectation(
    spendingPlanId: string,
    newPeriodicExpectation: RepeatExpectation,
    year: number,
  ): Promise<Expectation[]> {
    let expectations;

    if (!newPeriodicExpectation.id) {
      expectations = await this.getExpectationsByCategoryIdAndYear(
        spendingPlanId,
        newPeriodicExpectation.subCategory.id,
        year,
      );
    } else {
      expectations = newPeriodicExpectation.expectations;
    }

    const mapExpectations = new Map(
      expectations.map((expectation: Expectation) => [expectation.periodMonth, expectation]),
    );
    const expectationsToSave: Expectation[] = [];
    newPeriodicExpectation.months.forEach((amountExpectation) => {
      const month = amountExpectation.month.getMonth();
      let newExpectation;
      const expectationByMonth: Expectation = mapExpectations.get(month) as Expectation;
      let isCheckChanged = false;

      if (expectationByMonth) {
        newExpectation = expectationByMonth.clone();
        newExpectation.amount = amountExpectation.amount || 0;
        isCheckChanged =
          newExpectation.isFixed !== newPeriodicExpectation.isFixedChecked ||
          newExpectation.isScheduled !== newPeriodicExpectation.isPeriodicChecked;
        newExpectation.isFixed = newPeriodicExpectation.isFixedChecked;
        newExpectation.isScheduled = newPeriodicExpectation.isPeriodicChecked;
      } else {
        newExpectation = new Expectation();
        newExpectation.amount = amountExpectation.amount || 0;
        newExpectation.adjustment = 0;
        newExpectation.categoryId = newPeriodicExpectation.subCategory.id;
        newExpectation.periodYear = year;
        newExpectation.periodMonth = month + 1;
        newExpectation.isFixed = newPeriodicExpectation.isFixedChecked;
        newExpectation.isScheduled = newPeriodicExpectation.isPeriodicChecked;
      }

      if (!newExpectation.id || newExpectation.amount !== newExpectation.previousAmount || isCheckChanged) {
        expectationsToSave.push(newExpectation);
      }
    });

    const expectationsSaved = await Promise.all(
      expectationsToSave.map((expectationToSave) => this.saveExpectation(expectationToSave, spendingPlanId)),
    );

    return expectationsSaved;
  }

  async deletePeriodicExpectations(periodicExpectations: Array<string>, spendingPlanId: string): Promise<void> {
    await Promise.all(
      periodicExpectations.map(async (periodicExpectationId: string) => {
        await this.deletePeriodicExpectation(periodicExpectationId, spendingPlanId);
      }),
    );
  }

  async deletePeriodicExpectation(periodicExpectationId: string, spendingPlanId: string): Promise<void> {
    if (environment.useMocks) {
      // eslint-disable-next-line no-console
      console.log(':::Deleted PeriodicExpectation:::');
    } else {
      const url = `${this.apiUrl}/client/spendingPlans/${spendingPlanId}/scheduledExp/${periodicExpectationId}`;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
      const response = await this.http.delete(url).toPromise();
    }
  }
}
